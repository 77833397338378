// extracted by mini-css-extract-plugin
export var buttonAccept = "styles-module--buttonAccept--0c2e0";
export var buttonCancel = "styles-module--buttonCancel--36b8e";
export var buttonMobile = "styles-module--buttonMobile--b1ac7";
export var buttonsMobile = "styles-module--buttonsMobile--d45a6";
export var container = "styles-module--container--81e31";
export var containerMobile = "styles-module--containerMobile--36345";
export var linkTitleMobile = "styles-module--linkTitleMobile--414b3";
export var title = "styles-module--title--074a8";
export var titleButtonAccept = "styles-module--titleButtonAccept--4b869";
export var titleButtonCancel = "styles-module--titleButtonCancel--bf52f";
export var titleButtonMobile = "styles-module--titleButtonMobile--8b0bf";
export var titleMobile = "styles-module--titleMobile--14951";