import React from 'react';
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

const Header = () => (
  <>
    <DesktopMenu/>
    <MobileMenu/>
  </>
);

export default Header;







