import styled from "styled-components";

const InputContainer = styled.div `
  width: 100%;
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  
  .input-error {
    border: 1px solid #FCC3C3;

    &::placeholder {
      color: #FCC3C3;
    }
  }

  .error {
    color: #D96262;
    padding: 10px 0 0;
  }
  
  input, textarea {
    width: auto;
    
    border: none;
    outline: none;
    
    color: white;

    border-radius: 10px;
    background: #0E0A0E;
    padding: 25px 15px;

    &::placeholder {
      color: #8A8A8A;
      font-size: 16px;
      font-weight: 400;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 1000px #0E0A0E inset;
  }

  input[type="date"] {
    width: 100%;
  }

  textarea {
    height: 250px;
    resize: none;
  }
  
  .react-tel-input {
    display: contents;
  }
`

export default InputContainer;
