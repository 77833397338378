const colors = {
  green: "#4CEF16",
  black: "#000",
  white: "#FFF",
  purple: "#AE59F3",
  silver: "#E6E6E6",
  silverText: "#A0A0A0",
};

export default colors;
